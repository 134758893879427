import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container, Row, Col, Modal, ModalBody, Collapse } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPlayCircle,
  faTimesCircle,
  faTimes,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'
import mythbustingHeader from '../images/mythbusting.png'
import JumpSection from '../components/jump-section'

class BeTheChangeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accordionItemsOn: [],
    }
    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion(item) {
    let accordionItemsOn = this.state.accordionItemsOn

    if (accordionItemsOn.includes(item)) {
      const index = accordionItemsOn.indexOf(item)
      accordionItemsOn.splice(index, 1)
    } else {
      accordionItemsOn.push(item)
    }
    this.setState({ accordionItemsOn })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const pageTitle = get(this, 'props.data.contentfulBeTheChange.name')
    const videoBox = get(this, 'props.data.contentfulBeTheChange.videoBox')
    const tipsAirPollution = get(
      this,
      'props.data.contentfulBeTheChange.tipsToAvoidAirPollution'
    )
    const tipsForDrivers = get(
      this,
      'props.data.contentfulBeTheChange.tipsForDrivers'
    )
    const mythbusting = get(
      this,
      'props.data.contentfulBeTheChange.mythbusting'
    )

    const whatAreWeDoing = get(
      this,
      'props.data.contentfulBeTheChange.whatAreWeDoing'
    )

    const header = get(this, 'props.data.contentfulBeTheChange.header')
    const headerMobile = get(
      this,
      'props.data.contentfulBeTheChange.headerMobile'
    )

    const metaDescription = get(
      this,
      'props.data.contentfulBeTheChange.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulBeTheChange.metaImage.file.url'
    )

    const SlickButtonFix = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => <span {...props}>{children}</span>

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      infinite: true,
      prevArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Previous">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </SlickButtonFix>
      ),
      nextArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Next">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </SlickButtonFix>
      ),
    }

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={pageTitle + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={'https://cleanairgm.com/be-the-change'}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>
          <div className="page-banner">
            <Img
              fluid={header.fluid}
              className="page-banner__img d-none d-lg-block"
            />
            <Img
              fluid={headerMobile.fluid}
              className="page-banner__img d-block d-lg-none"
            />
            <h1>Be the change</h1>
          </div>
          <JumpSection
            links={[
              'What you can do',
              'Top tips',
              'Mythbusting: engine idling',
              "What we're doing",
            ]}
          />
          <Container
            id="what-you-can-do"
            className="video mb-5 text-center text-lg-left"
          >
            <Row>
              <Col xs={12} lg={{ size: 6 }} className="mb-4 mb-lg-0">
                <div className="video__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: videoBox.text.childMarkdownRemark.html,
                    }}
                  ></div>
                </div>
              </Col>
              <Col className="mb-4 mb-md-0" xs={12} lg={{ size: 6 }}>
                <div className="react-player-wrapper">
                  <ReactPlayer
                    width="100%"
                    className="react-player"
                    height="100%"
                    controls
                    url={videoBox.videoUrl}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <div id="top-tips" className="tips">
            <Container>
              <h2>{tipsAirPollution.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: tipsAirPollution.description.childMarkdownRemark.html,
                }}
              ></div>
            </Container>

            <Slider {...settings}>
              {tipsAirPollution.tips.map((t, i) => (
                <div key={`ap${i}`} className="tip-slide-container">
                  <div className={`tip-slide tip-slide--ap${i % 8}`}>
                    <Img
                      loading="eager"
                      objectFit="contain"
                      objectPosition="50% 50%"
                      className={`tip-slide__img`}
                      fluid={t.image.fluid}
                    />
                    <h2>{t.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t.description.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="tips tips--drivers">
            <Container>
              <h2>{tipsForDrivers.title}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: tipsForDrivers.description.childMarkdownRemark.html,
                }}
              ></div>
            </Container>
            <Slider {...settings}>
              {tipsForDrivers.tips.map((t, i) => (
                <div key={`d${i}`} className="tip-slide-container">
                  <div className={`tip-slide tip-slide--d${i % 8}`}>
                    <Img
                      loading="eager"
                      objectFit="contain"
                      objectPosition="50% 50%"
                      className={`tip-slide__img`}
                      fluid={t.image.fluid}
                    />
                    <h2>{t.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t.description.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div id="mythbusting-engine-idling" className="mythbusting">
            <div className="mythbusting__header">
              <img alt="" src={mythbustingHeader} />
              <Container className="text-center">
                <h2>Mythbusting: Engine idling</h2>
                <p>
                  Idling is leaving your vehicle’s engine running when it’s not
                  in motion. Here are some idling myths and facts, that could
                  help reduce your emissions and save money!
                </p>
              </Container>
            </div>
            <Container>
              {mythbusting.items.map((m, i) => (
                <div key={`myth${i}`} className="mythbusting__item">
                  <button
                    className={`mythbusting__button ${
                      this.state.accordionItemsOn.includes('toggler' + i)
                        ? 'on'
                        : 'off'
                    }`}
                    id={`toggler${i}`}
                    onClick={() => {
                      this.toggleAccordion(`toggler${i}`)
                    }}
                  >
                    {m.title}{' '}
                    <FontAwesomeIcon
                      className={`chevron  ${
                        this.state.accordionItemsOn.includes('toggler' + i)
                          ? 'on'
                          : 'off'
                      }`}
                      icon={faChevronDown}
                    />
                  </button>
                  <Collapse
                    className="p-4 p-lg-5"
                    isOpen={this.state.accordionItemsOn.includes('toggler' + i)}
                  >
                    <Row>
                      <Col sm={12} lg={6}>
                        <h2 className="mythbusting__title">
                          <FontAwesomeIcon className="mr-3" icon={faTimes} />
                          False
                        </h2>
                        <div
                          className="mythbusting__text"
                          dangerouslySetInnerHTML={{
                            __html: m.text.childMarkdownRemark.html,
                          }}
                        ></div>
                      </Col>
                      <Col sm={12} lg={6}>
                        <Img
                          className="mythbusting__img"
                          fluid={m.image.fluid}
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </div>
              ))}
            </Container>
          </div>
          {/* <Container className="mt-5 mb-5 pt-0 pb-5 text-center tips">
            <h2>Resources</h2>
            <div>
              <a target="_blank" href="https://assets.ctfassets.net/tlpgbvy1k6h2/4zMILogXsWeoM1EK9hVF05/d3b9fd9d95602b5a379d4b7cca5a0bf1/_Why_care_about_clean_air__public_leaflet.pdf" className="btn btn-resource mt-3 mr-4 ml-4">
                <FontAwesomeIcon className="mr-3" icon={faFilePdf} />
                'Why care about clean air?' leaflet
              </a>
              <a target="_blank" href="https://assets.ctfassets.net/tlpgbvy1k6h2/4ztIR3UBFIKkjlNFMCqYpa/a64ae3c859e481ef9a345368c034de21/_Greater_Manchester_s_journey_to_cleaner_air__flyer.pdf" className="btn btn-resource mt-3 mr-4 ml-4">
                <FontAwesomeIcon className="mr-3" icon={faFilePdf} />
                'Greater Manchester’s journey to clean air' leaflet
              </a>
            </div>
          </Container> */}
          <Container
            id="what-were-doing"
            className="mt-5 mb-5 text-center text-lg-left"
          >
            <Row>
              <Col className="mb-4 mb-md-0 vc" xs={12} md={6}>
                <Img className="br-10" fluid={whatAreWeDoing.image.fluid} />
              </Col>
              <Col xs={12} md={{ size: 6 }} className="vc">
                <div className="video__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: whatAreWeDoing.text.childMarkdownRemark.html,
                    }}
                  ></div>
                  <div>
                    <Link
                      to={'/clean-air-plan'}
                      className="btn btn-content mt-3"
                    >
                      More on the Clean Air Plan
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BeTheChangeIndex

export const pageQuery = graphql`
  query BeTheChangeQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBeTheChange {
      id
      name
      metaDescription
      metaImage {
        file {
          url
        }
      }
      header {
        fluid(maxWidth: 1920, maxHeight: 450, quality: 95) {
          ...GatsbyContentfulFluid
        }
      }
      headerMobile {
        fluid(maxWidth: 1080, maxHeight: 720) {
          ...GatsbyContentfulFluid
        }
      }
      videoBox {
        videoUrl
        text {
          childMarkdownRemark {
            html
          }
        }
      }

      tipsToAvoidAirPollution {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        tips {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 390, maxHeight: 390) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      tipsForDrivers {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        tips {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 390, maxHeight: 390) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }

      mythbusting {
        title
        items {
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 580, maxHeight: 512) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }

      whatAreWeDoing {
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 480) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
